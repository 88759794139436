<template>
  <div id="attribute">
    <div class="Attribute_name" v-show="show && currentPanel != ''">
      <div class="name">{{ currentPanel }}</div>
    </div>
    <div :class="this.scrollShow ? 'histogram' : 'histogram_versions'" v-show="show" @scroll="scrollEvent($event)">
      <!-- HINT echarts -->
      <div class="echart" id="mychart"></div>
      <!-- HINT 四分线遮罩 -->
      <div class="shade" id="shade">
        <img src="https://newstore.vynior.com/Frame%201279%20%281%29.png" style="position: relative; top: 0px; left: -56px" alt="" />
        <div class="footer" id="footer" style="background-color: #fff8f5; z-index: 1000"></div>
      </div>
      <div class="box_data">
        <div class="title_box">
          <div class="data_title_header">
            <div class="data_title" v-for="(item, index) in productNameList" v-show="index < 30" :key="index" :for="index">
              <div class="data_titles">
                <el-tooltip class="item" :open-delay="500" effect="dark" placement="top" popper-class="back_ground">
                  <div slot="content" style="line-height: 18px">
                    <p>{{ item.program }}</p>
                    <p>{{ item.product }}</p>
                  </div>
                  <p>
                    {{ item.program }}
                    <br />
                    {{ item.product }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="bycial">
          <!-- 左侧属性名 -->
          <div class="namelist" ref="leftForm" @scroll="scrollLeft()" id="item">
            <div
              v-for="(item, index) in panelNameList"
              :key="index"
              @mouseleave="hoverPanel = ''"
              @mouseenter="hoverPanel = item"
              @click="assignmentEcharts(index)"
              :class="currentPanel == item ? 'item_title_versions' : hoverPanel == item ? 'item_title_hover' : 'item_title'">
              <el-tooltip :open-delay="500" class="item" effect="dark" :content="item" placement="top" popper-class="back_ground">
                <div>
                  {{ item }}
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="box_flex_num" ref="rightForm" @scroll="scrollRight()">
            <div
              class="priduct_num_data"
              @mouseleave="hoverPanel = ''"
              @mouseenter="hoverPanel = panelNameList[index]"
              v-for="(item, index) in panelData"
              :key="index"
              @click="assignmentEcharts(index)"
              :class="currentPanel == panelNameList[index] ? 'BGcolor' : hoverPanel == panelNameList[index] ? 'BGcolor_hover' : 'priduct_num_data'">
              <div class="item-right">
                <span class="item" v-for="(data, indexs) in item" :key="indexs">{{ Number(data).toFixed(1) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_none" v-show="this.show == false">
      <!-- 柱状图 -->
      <div class="none_echart">
        <div class="echart_num1">
          <span>15</span>
          <img src="https://newstore.vynior.com/Line%20118%402x.png" alt="" />
        </div>

        <div class="echart_num2">
          <span>10</span>
          <img src="https://newstore.vynior.com/Line%20118%402x.png" alt="" />
        </div>

        <div class="echart_num3">
          <span>5</span>
          <img src="https://newstore.vynior.com/Line%20118%402x.png" alt="" />
        </div>

        <div class="echart_num4">
          <span>0</span>
          <div class="spanss"></div>
        </div>

        <!-- 柱子 -->
        <div class="echart_ZZ">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>

        <div class="echart_ZZ1">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ2">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ3">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ4">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ5">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ6">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ7">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ8">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ9">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ10">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ11">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ12">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ13">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
        <div class="echart_ZZ14">
          <img src="https://newstore.vynior.com/Rectangle%201486%402x.png" alt="" />
        </div>
      </div>

      <!-- 感官属性 -->
      <div class="none_valG">
        <!-- 横排属性名栏 -->
        <div class="valG_lan">
          <span class="span">--<br />--</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span>-- --</span>
          <span class="span2">--<br />--</span>
        </div>

        <!-- 竖排属性栏 -->
        <div class="valG_lie">
          <!-- 默认显示 -->
          <ul v-show="panelNameList.length == 0">
            <li>--</li>
            <li>--</li>
            <li>--</li>
            <li>--</li>
            <li>--</li>
            <li>--</li>
            <li>--</li>
            <li>--</li>
          </ul>

          <!-- 点击产品名 显示 -->
          <div class="valG_lie_name" v-show="panelNameList.length != 0">
            <p class="valG_lie_names" v-for="(item, index) in panelNameList" :key="index">
              {{ item }}
            </p>
          </div>
        </div>

        <!-- 暂无数据 -->
        <div class="noned">
          <img src="https://newstore.vynior.com/Group%2033628%402x.png" alt="" />
          <p>{{ $t(`message.History.NoData`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

var myChart;
export default {
  data() {
    return {
      panelNameList: [], // 感官属性名称
      show: false, // 是否显示
      scrollShow: false, // 滚动条显示权限
      productNameList: [], // 选中产品名称
      currentIndex: 0,
      currentPanel: '', // 点击选择
      hoverPanel: '', // 移动选择
      panelData: [], // 感官属性数据
      showData: [], // 柱状图数据，
    };
  },
  mounted() {
    if (this.$store.state.productNameList.length === 8) {
      // !拿数组进行遍历
      let arr = this.$store.state.panel_data;
      console.log('arr我要处理数据了', arr);
      let arr1 = [];
      arr.forEach((item) => {
        let arr2 = item.slice(0, 8);
        arr1.push(arr2);
      });
      console.log('arr我要处理数据了', arr1);
      this.$store.commit('$_changePanel_data', arr1);
    }
    this.$nextTick(() => {
      this.initEcharts();
      console.log('渲染调用函数');
    });
    if (this.$store.state.panelNameList.length != 0) {
      this.panelNameList = this.$store.state.panelNameList;
    }
    if (this.$store.state.productNameList.length != 0) {
      this.productNameList = this.$store.state.productNameList;
    }
    if (this.$store.state.panel_data.length != 0) {
      this.panelData = this.$store.state.panel_data;
      this.show = true;
      this.assignmentEcharts(this.currentIndex);
      this.initEcharts();
    }
    if (this.$store.state.lang == 'cn') {
      this.panelNameList = this.$store.state.panelNameList;
    } else {
      console.log('英文');
      this.panelNameList = this.$store.state.panelNameList_en;
    }
  },
  beforeMount() {},
  created() {},
  watch: {
    // productNameList
    '$store.state.panelNameList': {
      handler() {
        if (this.$store.state.panelNameList.length != 0) {
          this.panelNameList = this.$store.state.lang == 'cn' ? this.$store.state.panelNameList : this.$store.state.panelNameList_en;
        } else {
          this.panelNameList = [];
        }
      },
      deep: true,
    },
    '$store.state.productNameList': {
      handler(newvalue) {
        if (this.$store.state.productNameList.length != 0) {
          this.productNameList = this.$store.state.productNameList;
        } else {
          this.productNameList = [];
        }
        if (newvalue.length > 15) {
          let dom = document.getElementById('shade');
          dom.style.marginTop = '-13px';
          let footer = document.getElementById('footer');
          footer.style.display = 'none';
        } else {
          let dom = document.getElementById('shade');
          dom.style.marginTop = '0';
          let footer = document.getElementById('footer');
        }
      },
      deep: true,
    },
    '$store.state.panel_data': {
      handler() {
        if (this.$store.state.panel_data.length != 0) {
          this.panelData = this.$store.state.panel_data;
          this.show = true;
          if (this.panelData[0].length > 15) {
            this.scrollShow = true;
            this.initEcharts();
          }
          this.assignmentEcharts(this.currentIndex);
          this.initEcharts();
        } else {
          this.panelData = [];
          this.showData = [];
          this.show = false;
          this.scrollShow = false;
          this.currentIndex = 0;
          this.currentPanel = '';
          this.hoverPanel = '';
        }
      },
      deep: true,
    },
    '$store.state.lang': {
      handler() {
        // initEcharts()
        console.log('this.$store.state.lang :>> ', this.$store.state.lang);
        if (this.panelData.length !== 0) {
          this.assignmentEcharts(this.currentIndex);
        }
        if (this.$store.state.lang == 'cn') {
          this.panelNameList = this.$store.state.panelNameList;
        } else {
          console.log('英文');
          this.panelNameList = this.$store.state.panelNameList_en;
        }
      },
    },
  },
  methods: {
    // NOTE 柱状图
    initEcharts() {
      // 基本柱状图
      const option = {
        color: '#FF6C40',
        xAxis: {
          type: 'category',
          color: '#D9D9D9',
          data: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          yMax: 15,
          splitNumber: 3,
          min: 0,
          max: 15,
          splitLine: {
            show: true,
            lineStyle: { type: 'dashed' },
          },
          axisLine: {
            lineStyle: {
              color: '#D9D9D9',
            },
          },
          axisLabel: {
            textStyle: {
              color: '#D9D9D9',
            },
          },

          // axisLine: {
          //   show: false,
          // },
        },
        series: [
          {
            data: this.showData,
            type: 'bar',
            barWidth: '54px',
            // barCategoryGap:'60px',
            showBackground: true,
            backgroundStyle: {
              color: '#FAFAFA',
            },

            itemStyle: {
              color: '#FF6C40',
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    //数值样式
                    color: '#FF6C40',
                    fontSize: 16,
                  },
                },
              },
            },
            axisLine: {
              lineStyle: {
                color: '#e33b38',
                width: 1,
              },
            },
          },
        ],

        grid: {
          top: 0,
          let: 0,
          x: 0,
          y: 0,
          bottom: 1,
          right: 0,
        },
      };
      if (myChart != null && myChart != '' && myChart != undefined) {
        myChart.dispose(); //销毁
      }
      myChart = echarts.init(document.getElementById('mychart'));

      myChart.setOption(option);
      // // 随着屏幕大小调节图表;
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    // NOTE 选中数据赋值
    assignmentEcharts(index) {
      this.currentIndex = index;
      this.currentPanel = this.$store.state.lang == 'cn' ? this.$store.state.panelNameList[index] : this.$store.state.panelNameList_en[index];
      let newData = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
      this.panelData[index].forEach((item, indexs) => {
        newData[indexs + 1] = Number(item).toFixed(1);
      });
      this.showData = newData;
      this.initEcharts();
    },
    // NOTE 滚动方法
    scrollLeft() {
      this.$refs.rightForm.scrollTop = this.$refs.leftForm.scrollTop;
    },
    scrollRight() {
      this.$refs.leftForm.scrollTop = this.$refs.rightForm.scrollTop;
    },
    // NOTE 实时获取柱状图位置
    scrollEvent(e) {
      let BottomValue = e.target.scrollLeft;
      // 绑定  属性名的id
      let name = document.getElementById('item');
      // 绑定  空白遮罩的id
      let shade = document.getElementById('shade');
      let val = BottomValue + 'px';
      name.style.zIndex = 100;
      name.style.paddingLeft = val;
      shade.style.left = val;
    },
  },
};
</script>

<style lang="less" scoped>
@import url('../../style/History/attribute.less');
</style>
